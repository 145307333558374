import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout
      location={location}
      title={siteTitle}
      about={data.allContentfulAbout}
    >
      <Seo title="404: Not Found" />
      <h1>404: Not Found</h1>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulAbout {
      edges {
        node {
          aboutDescription {
            raw
          }
          pressLinks {
            linkTitle
            link
          }
          socialMediaLinks {
            linkTitle
            link
          }
        }
      }
    }
  }
`
